// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

@tailwind base;
@tailwind components;
@tailwind utilities;

// Import required fonts: 
// Open Sans
// Raleway
// Roboto Condensed
// Roboto
// Source Sans Pro
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400&family=Roboto:wght@100;300;400;500;700;900&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

// NOTE FOR THE FUTURE:
// This palette was generated using http://mcg.mbitson.com/
// It is hard-coded for now - we might want to have the palette configurable in the future
// It IS possible to have a dynamic palette that is changeable during runtime
// This article explains the steps: https://medium.com/angular-in-depth/build-truly-dynamic-theme-with-css-variables-539516e95837
$md-grey: (
  50 : #f0f0f0,
  100 : #dadada,
  200 : #c1c1c1,
  300 : #a8a8a8,
  400 : #969696,
  500 : #838383,
  600 : #7b7b7b,
  700 : #707070,
  800 : #666666,
  900 : #535353,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$signup-primary: mat.m2-define-palette($md-grey);
$signup-accent: mat.m2-define-palette($md-grey, A200, A100, A400);

// The warn palette is optional (defaults to red).
$signup-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$signup-theme: mat.m2-define-light-theme((
  color: (
    primary: $signup-primary,
    accent: $signup-accent,
    warn: $signup-warn,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($signup-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// force the density to be 0 to ensure global app styling does not override
.signup-form {
  @include mat.all-component-densities(0);
}